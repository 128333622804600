@import "variables";

$additionalInfoWidth: 430px;

.nonExpertView {
    &__newSubGroup {
        font-size: small;
        background-color: $primaryLight;
        //padding: 2px 5px;
        border-top: 1px solid $gray0;
      }
    &__newSubGroup:hover {
        color: $coralColor;
        svg { margin-right: 5px; }
    }
    &__chevron {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: white;
        width: 60px;
        justify-content: center;
        i { margin: 0; color: $secondary; }
    }

    &__isSelected {
        .dataRightsItem__chevron {
            color: $coralColor;
        }
        // i { color: $coralColor !important; }
        // svg-icon { color: $coralColor !important; }
        // input { color: $coralColor !important; }
    }

    &__wrapper {
        // border: 1px solid gainsboro;
        // background-color: white;
        background-color: $gray0;
        padding: 0;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        // margin-bottom: 3px;
        position: relative;
    }

    &__main {
        display: flex;
        align-items: center;
        //height: 48px;
        justify-content: space-between;
        padding: 0;
        background-color: $primaryLight;
        @media (max-width: $desktopBreakPoint) {
            flex-direction: column;
            height: auto;
        }
        &__noSubitems {
            //margin-bottom: 5px;
            border:$globalBorder;
        }
    }

    &__bonus {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        //width: 70px;
        justify-content: center;
        padding: 0 10px;
        color:$fontDark;
        // background-color: #eaeaea;
        //margin: 0 3px;
        min-width: 70px;

        app-number-input{
            width:80px;
        }
        svg { margin: 0 5px }
        //i:hover { margin: 0 5px; color: $secondaryShade2 }
    }

    &__smallText {
        min-width: 200px;
        max-width: 200px;
        input, select {
            width: calc(100% - 5px);
        }
    }

    &__order {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;
        padding: 0 10px;
        color:$fontDark;
        min-width: 120px;
        max-width: 120px;

        app-number-input{
            width: 100%;
        }
    }

    &__groupLevel {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 100px;
        justify-content: space-between;
        padding: 0 10px;
        background-color: #eaeaea;
        margin: 0 3px;
        input { width: 100%; margin-left: 5px; }
    }

    &__name {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding: 10px;
        color:$secondary;
        font-size: 14px;
        font-weight: $fontBold;
        svg { margin-right: 5px; }

        app-text-input, input {
            min-width: 300px;
            width: 100%;
            @media (max-width: $desktopBreakPoint) {
                min-width: 200px;
            }
        }
        @media (max-width: $desktopBreakPoint) {
            padding: 0;
            height: 50px;
        }
        label {
            margin: 0;
        }
    }
    &__buttons {
        display: flex;
        width: 150px;
        min-width: 150px;
        height: 100%;
        margin: 0 3px;
        justify-content: space-between;
        align-items: center;
        background-color: RGB(234,234,234);

        &__container {
            width: 30px;
            min-width: 30px;
            max-width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__chevron {
                // background-color: white;
                padding: 5px;
                width: 40px;
                max-width: 40px;
                min-width: 40px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 40px;
                svg { margin: 0; }
            }
        }

        &__button {
            // color: white;
            color: $blueColor;
            // border-radius: 50%;
            width: 27px;
            height: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            padding: 0 5px;
        }
    }

    &__aditionalInformation {
        display: flex;
        align-items: center;
        min-width: $additionalInfoWidth;
        max-width: $additionalInfoWidth;
        background-color: $primaryLight;
        height: 100%;
        @media (max-width: $desktopBreakPoint) {
            height: 40px;
            min-width: 100%;
            max-width: 100%;
        }
    }
}

.nonExpertHeader {
    display: flex;
    align-items: center;
    margin: 5px 0;
    color:$secondary;
    //color: rgba(0, 0, 0, 0.54);
    font-weight: $fontBold;

    &__name {
        width: 100%;
        font-size: $font-textSize;
    }
    &__active {
        min-width: 85px;
        font-size: $font-textSize;
        display: flex;
        justify-content: center;
    }
    &__netto {
        min-width: 60px;
        font-size: $font-textSize;
        display: flex;
        justify-content: center;
    }
    &__edit {
        font-size: $font-textSize;
        display: flex;
        justify-content: center;
        min-width: 80px;
    }
    &__order {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        max-width: 120px;
    }
}
