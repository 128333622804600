table {
    background-color: $primaryLight;
    border-collapse: separate !important;
}


.mdc-data-table__table{
    border-spacing:  0px 10px !important;
}


.mat-row{
    //padding:1rem 0 !important;
    //display: block !important;
    //margin-bottom: 15px !important;
    //border-spacing:20px !important;
}


td.mat-mdc-cell {
    background-color: $primaryLight !important;
    color: $fontDark;  
    // border-right: 2px solid $secondary; 
}


.mat-mdc-header-row {
    font-weight: $fontBold !important;
    background-color: $primaryLight !important;
    color: $secondary !important;
    height:50px !important;
    
}


.mat-mdc-header-cell, .mat-mdc-cell {
    border-bottom-color: transparent !important;
    border-bottom-width: transparent !important;
    border-bottom-style: transparent !important;
    letter-spacing: 0;    
    line-height: inherit;
    text-align: left !important;
}
 

