/* .customTextControlWrapper {
    &__dataRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg-item { margin-left: 5px; }
        i { margin-left: 5px; }
    }
    &__label {
        width:100%;
        font-size: 13px;
        color: gray;
        min-height: 20px;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }
    &__dropdown {
        color: $blueColor !important;
        font-weight: bold;
        background-color: white;
        font-size: 13px;
    }
    &__datetime {
        color: $blueColor !important;
        font-weight: bold;
        background-color: white;
    }
    &__textbox {
        outline: 0;
        border-width: 0 0 0 0;
        height: 24px;
        width: 100%;
        color: $blueColor !important;
        font-weight: bold;
        background-color: white;
        border-bottom: 1px solid transparent;
        text-overflow: ellipsis;
    }
    &__textbox:disabled {
        background-color: transparent;
        color: gray !important;
    }
    &__textbox:focus {
        color: $coralColor !important;
    }
    &__button {
        fill: $blueColor !important;
        color: $blueColor !important;
    }
    &__button:hover {
        i {
            color: $coralColor !important;
            fill: $coralColor !important;
        }
        color: $coralColor !important;
        fill: $coralColor !important;
    }
    // &__oneIcon {
    //     width: calc(100% - 25px);
    // }
    // &__twoIcons {
    //     width: calc(100% - 50px);
    // }
    // &__threeIcons {
    //     width: calc(100% - 75px);
    // }
    &__iconStyle {
        fill: $blueColor !important;
        color: $blueColor !important;
    }
    &__baseColor {
        fill: $blueColor !important;
        color: $blueColor !important;
    }
    &__coralColor {
        fill: $coralColor !important;
        color: $coralColor !important;
    }
    &__greenColor {
        color: $greenColor;
        fill: $greenColor;
    }
    &__baseBackground {
        background-color: $blueColor !important;
    }
    &__greenBackground {
        background-color: $greenColor !important;
    }
    &__Background {
        background-color: $blueColor !important;
    }

    &__baseBackgroundHover {
        background-color: $blueColor !important;
    }
    &__baseBackgroundHover:hover {
        background-color: $coralColor !important;
    }

    display: flex;
    flex-direction: column;
    margin-top: -3px;
    margin-right: 5px;
} */




.customTextControlWrapper {
    position: relative;
    /* display: flex;
    flex-direction: column;
    margin-top: -3px;
    margin-right: 5px; */

    &__disabled{
       .customTextControlWrapper__label{
        color: 1px solid gray;
       }
    }

    &__label {
        position:relative;
        //top:12px;
        top:13px;
        padding:0 0.2rem;
        left:8px;
        color: $fontBlack !important;
        font-size: 12px;
        font-weight: 400px;
        line-height: 18px;
   
        margin: 0;
        background-color: $primaryLight;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    &__valueWrapper{
        border: $globalBorder;
        border-radius: $globalBorderRadius;
        display:flex;
        align-items: center;
        padding:10px 8px 10px 8px;
        height:30px;

        svg{
            fill:$secondary !important;
        }

        &:hover{
            border:$globalBorderHover;
        }
    }

    &__valueWrapperTextArea{
        border: $globalBorder;
        border-radius: $globalBorderRadius;
        display:flex;
        align-items: center;
        padding:10px 8px 10px 8px;
        height:80px;
        font-size: 16px;

            svg{
                fill:$secondary !important;
            }

            &:hover{
                border:$globalBorderHover;
            }
        }
    

    &__dataRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg-item { margin-left: 5px; }
        i { margin-left: 5px; }
    }


    &__dropdown {
        border:none !important;
        color: $fontDark !important;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        display: inline-block;
            width:100%;
            padding-right:30px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: none;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0iIzU0NjVmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==');
            background-repeat:no-repeat;
            background-position: right 10px center;
            background-size: 12px 12px;
            cursor:pointer;
            font-size: 14px;
        
    
        &::-ms-expand{
            display:none
        }

        &:focus{
            outline: $secondary !important;
        }
    }
    &__datetime {
        color: $secondary !important;
        font-weight: bold;
        background-color: white;
    }


    &__textArea{
        resize: none;
        height: 100%;
        border:none;
        width:100%;
        background-color: $primaryLight;
        font-size: 14px !important;
        

        &:focus{
            height: auto;
            margin-bottom: -1px;
            border-bottom: 1px solid gray;
            
        }
    }

    &__textbox {
        outline: 0;
        width: 100%;
        color: $fontDark !important;
        font-weight: 500;
        border:none !important;
        text-overflow: ellipsis;


       &:focus{
        outline : none;
       }
    }

    &__textbox:disabled {
        background-color: transparent;
        color: gray !important;
    }

    &__textbox:focus {
        color: $coralColor !important;
    }
    &__button {
        fill: $secondary !important;
        color: $secondary !important;
    }
    &__button:hover {
      i {
            color: $secondary !important;
            fill: $secondary !important;
        }
        color: $secondary !important;
        fill: $secondary !important; 
    }
    // &__oneIcon {
    //     width: calc(100% - 25px);
    // }
    // &__twoIcons {
    //     width: calc(100% - 50px);
    // }
    // &__threeIcons {
    //     width: calc(100% - 75px);
    // }
/*     &__iconStyle {
        fill: $secondary !important;
        color: $secondary !important;
    }
    &__baseColor {
        fill: $blueColor !important;
        color: $blueColor !important;
    }
    &__coralColor {
        fill: $coralColor !important;
        color: $coralColor !important;
    }
    &__greenColor {
        color: $greenColor;
        fill: $greenColor;
    }
    &__baseBackground {
        background-color: $blueColor !important;
    }
    &__greenBackground {
        background-color: $greenColor !important;
    }
    &__Background {
        background-color: $blueColor !important;
    }

    &__baseBackgroundHover {
        background-color: $blueColor !important;
    }
    &__baseBackgroundHover:hover {
        background-color: $coralColor !important;
    } */


}
