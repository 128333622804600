/*
Global variables
this file resolved globally in angular.json
import it anywhere with "@import 'variables'"
*/

// colors
$coralColor: #5465FF;
$blueColor: #5465FF;
$greenColor: #3FE163;

$buttonColor: #455A64;
$buttonHoverColor: #56717D;
$buttonColorDark: #2B303C;

// breakpoints
$mobileBreakPoint: 700px;
$tabletBreakPoint: 1025px;
// ! middle break point cannot be 1280px 
$middleDesktopBreakPoint: 1335px;
$desktopBreakPoint: 1400px;
$largeBreakPoint: 1600px;
$designBreakPoint: 1600px;

$accountFormBreakPoint: 1300px;

$popupBackground: rgba(0, 0, 0, 0.3);



// * New Design

// MAIN COLORS
$backgroundColorDarkMode: #000000;
$backgroundColorDarkMode2: rgb(30,30,30);

$primaryLight: #FFFFFF;
$primaryDark: #FBFBFB;
$secondary:#5465FF;
//$secondary:#5467DA;

$secondaryShade1:#5162F6;
$secondaryShade2:#788BFF;
$secondaryShade3:#9BB1FF;
$secondaryShade4:#BFD7FF;
$secondaryShade5: #F5F6FF;

$gray0:#DFDFDF;

$disabled:#d2d2d2;
$disabledGray: $disabled;
//$disabledGray:#808080;
$fontMid: #D2D2D2;
$fontBlack: #333333;

//$headerBackgroundColor: #E2FDFF;

$tertiary: #E2FDFF;


// FONT COLORS 
$fontLightColor: #FFFFFF;
$fontMidColor:#DFDFDF;
$fontDark:#333333;




// FUNCTION COLORS:
$functionRedColor:#FF5050;
$functionYellowColor:#FFD600;
$functionGreenColor:#3FE163;
$functionOrangeColor:#FF6B00;
$boxBorderGrey:#EDEDED;

$globalBorder: 1px solid $boxBorderGrey;
$globalSecondaryBorder: 1px solid $secondary;

$globalBorderHover: 1px solid $secondary;
$globalBorderRadius: 8px;



// FONTS
$font-family:'Poppins';
$fontBold:600;
$fontNormalWeight:400;


// fontText
$font-textSize: 0.875rem ; //14px;
$font-textWeight:400;
$font-textLineHeight:21px; //
$font-text: 'Poppins' 0.875rem 400  21px;


// font kicker
$font-kicker: 'Poppins' 1rem 400 24px #5465FF;
$font-kickerSize: 1rem ; //16px;


$inputFontSize:14px;

// HEADERS

$headerWeight:600;

// h1
$headerH1Size: 1.75rem ;//28px;
$headerH1LineHeight: 42px;
$headerH1: 'Poppins' 1.75rem 600 42px;

// h2
$headerH2Size: 1.5rem; //24px;
$headerH2LineHeight: 36px;
$headerH2: 'Poppins' 1.5rem 600 36px ;

// h3
$headerH3Size: 1.25rem; //20px;
$headerH3LineHeight:30px;
$headerH3: 'Poppins' 1.25rem 600 30px;

// h4
$headerH4Size: 1rem; // 16px;
$headerH4LineHeight:24px;
$headerH4: 'Poppins' 1rem 600 24px;








