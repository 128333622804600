.everleadControlRegisterBlue{
    width:100%;
       padding:0.5rem;
   
       .everleadSelectWrapper__label{
           display:none;
       }
   
       .everleadSelectWrapper__valueWrapper{
           width:100%;
           background-color: $secondary;
           color:$primaryLight !important;
           background-color: $secondary;
           height:40px;
           padding:10px 8px;
   
           .select__box{
               position: relative;
               display: inline-block;
               width: 100%;
               color:$primaryLight;
                
   
               select{
                   width:100%;
                   padding-right:30px;
                   appearance: none;
                   -webkit-appearance: none;
                   -moz-appearance: none;
                   border: none;
                   background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iI2ZmZmZmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==');
                   background-repeat:no-repeat;
                   background-position: right 10px center;
                   background-size: 12px 12px;
                   cursor:pointer;
                   font-size: 14px;
                   color: $primaryLight;
   
   
                   option{
                       background-color: $secondary;
                       color:$primaryLight;
                       width:100%;
                       border-radius: 10px;
                       padding:0.5rem;
                       font-size: 14px;
   
                       &:checked{
                           background-color: $tertiary;
                           color:$fontDark;
                       }
   
                       &:hover{
                           background-color: $tertiary !important;
                           color:$fontDark;
                       }
                   }
               }
   
   /*             select:after {
                   content: attr(data-selected);
                   position: absolute;
                   right: 10px;
                   top: 50%;
                   transform: translateY(-50%);
                   pointer-events: none;
                   font-size: 14px;
                   color: $primaryLight;
                 } */
   
            
       }
   
   }
   
   }



   .everleadMobileTab{
    width:100%;
    padding:0.5rem;
   
       &__label{
           display:none;
       }
   
       &__valueWrapper{
           width:100%;
           background-color: $primaryLight;
           color:$primaryLight !important;
           height:40px;
           padding:10px 8px;
   
           .selectTab__box{
               position: relative;
               display: inline-block;
               width: 100%;
               color:$secondary;
               border-bottom:none !important;
                
   
               select{
                   width:100%;
                   padding-right:30px;
                   appearance: none;
                   -webkit-appearance: none;
                   -moz-appearance: none;
                   border: none !important;
                   border-bottom:none !important;
                   background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0iIzU0NjVmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==');
                   background-repeat:no-repeat;
                   background-position: right 10px center;
                   background-size: 12px 12px;
                   cursor:pointer;
                   font-size: 14px;
                   color: $primaryLight;

                   &:focus{
                    outline:none !important;
                   }

                   &:active{
                    outline:none !important;
                   }
                   
   
   
                   option{
                       background-color: $secondary;
                       color:$primaryLight;
                       width:100%;
                       border-radius: 10px;
                       padding:0.5rem;
                       font-size: 14px;
   
                       &:checked{
                           background-color: $tertiary;
                           color:$fontDark;
                       }
   
                       &:hover{
                           background-color: $tertiary !important;
                           color:$fontDark;
                       }
                   }
               }

            /*    select:focus{
                outline:none;
                border:2px solid transparent !important;
               }

               select:active{
                outline: none !important;
                border:2px solid transparent !important;
               } */
   
   /*             select:after {
                   content: attr(data-selected);
                   position: absolute;
                   right: 10px;
                   top: 50%;
                   transform: translateY(-50%);
                   pointer-events: none;
                   font-size: 14px;
                   color: $primaryLight;
                 } */
   
            
       }

       .selectTab__box:focus{
        outline:none !important;
       }

       .selectTab__box:active{
        outline:none !important;
       }


   
   }
   
   }